const whiteListLogin = [
	'/client/email',
	'/Client/email',

	'/login',
	'/login/recovery',
	'/login/registration',
	'/login/oauth/yandex',
	'/login/oauth/google',
	'/login/oauth/vk',
	'/login/sign-in',
	'/payment/education',
	'/payment/training',
	'/login-instant',
]

const whiteList = ['/shop/certificates', '/client/application', '/client/email', '/Client/email'];

export default async function ({$cookies, redirect, store, route, app, path}) {
	//todo: remove in September
	if (route.path.includes('/Client/Therapist/View')) {
		if (route.query && route.query.id) {
			if (route.query.id.includes('?')) {

				let split = route.query.id.split('?');

				if (split.length > 1) {
					redirect(`/search/psychologist/details?id=${split[0]}&${split[1]}`)
				} else {
					redirect('/search/psychologist')
				}
			} else {
				redirect(`/search/psychologist/details?id=${route.query.id}`)
			}
		} else {
			redirect('/search/psychologist')
		}
	}

	if (route.path.includes('/search/psychologist/') && !route.path.includes('/search/psychologist/details')) {
		let split = route.path.split('/search/psychologist/');

		if (split.length > 1) {
			redirect(`/search/psychologist/details?id=${split[1]}${route.query? '&' + route.query: ''}`)
		} else {
			redirect('/search/psychologist')
		}
	}

	if (!store.state.auth.isAuth) {
		try {
			await store.dispatch('auth/setCookiesAuth')
		} catch (e) {
			console.log(e);
		}
	}

	const isAuthenticated = store.state.auth.isAuth

	if (!isAuthenticated) {
		if (route.name === 'client-check-devices' || route.name === 'search-psychologist-details' || route.query.referrer_id) {
			if (route.query.referrer_id) {
				$cookies.set('referrer_id', route.query.referrer_id);
			}
			$cookies.set('redirect_url', route.fullPath);
		} else if (route.name === 'shop-subscriptions') {
			$cookies.set('redirect_url', route.fullPath);
			redirect({path: '/login'})
		}
	}

	try {
		if(process.env.IS_LOCAL) return
	} catch (e) {
		console.log(e);
	}

	if (!whiteList.includes(route.path) && !route.path.startsWith('/search')) {
		if (!isAuthenticated && !whiteListLogin.includes(route.path) && !route.path.includes('login/registration')) {
			redirect({path: '/search/psychologist'})
		} else {
			if (isAuthenticated && (whiteListLogin.includes(route.path) || route.path === "/")) {
				redirect({
					path: store.state.user.userType === "CLIENT" ? '/client/main' : '/psychologist/sessions',
				})
			}
		}
	}
}

