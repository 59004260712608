import snakeToCamel from '~/helpers/snakeToCamel'
import {getCurrentTimeZone} from "@/helpers/getCurrentTimeZone";

const apiSsoPath = '/sso/api/v1'
const apiRubricatorPath = '/rubricator/api/v1'
const apiAcquiringPath = '/acquiring/api/v1'

export const userTypes = {
	psychologist: 'PSYCHOLOGIST',
	client: 'CLIENT',
}

const initialState = {
	birthDate: '',
	changedOtp: '',
	email: '',
	id: '',
	middleName: '',
	password: '',
	permissions: '',
	phoneNumber: '',
	surname: '',
	timeZone: getCurrentTimeZone(),
	mailingAgreement: false,
	userType: '',
	username: '',
	createdAt: '',
	isNotB2BUser: false,
	b2bCompany: null,
	subscriptions: [],
	certificates: [],
	promocodes: [],
	underControl: false,
	specialistsCosts: [],
	loyaltyAccount: null,
	sessionQuotas: null,
	quotas: []
}

export const state = () => ({...initialState})

export const getters = {
	gtmUserPage(s) {
		if (s.userType === userTypes.psychologist) return 'psychologyPage'
		return `${s.userType?.toLowerCase() || ''}Page`
	},
}

export const mutations = {
	/**
	 *setUserData
	 *
	 * @param {*} state
	 * @param {*} userData
	 */
	setUserData(state, userData) {
		state = Object.assign(state, userData)
	},

	/**
	 *setInitialData
	 *
	 * @param {*} state
	 */
	setInitialData(state) {
		state = Object.assign(state, initialState)
	},

	setSubscriptions(state, subscriptions) {
		state.subscriptions = subscriptions;
	},

	setCertificates(state, certificates) {
		state.certificates = certificates;
	},

	setPromocodes(state, promocodes) {
		state.promocodes = promocodes.filter((promo) => {
			return (state.isNotB2BUser && !promo.b2b_company_id) || (state.b2bCompany?.id === promo.b2b_company_id);
		});
	},
	setTimezone(state, timezone) {
		state.timeZone = timezone
	},
	setSpecialistsCosts(state, cost) {
		state.specialistsCosts = cost;
	},
	setSessionQuotas(state, sessionQuotas) {
		state.sessionQuotas = sessionQuotas;
	},
	setQuotas(state, quotas) {
		state.quotas = quotas;
	}
}

export const actions = {
	/**
	 * Fetch user settings for Client
	 */
	async fetchClient({dispatch}) {
		const url = `${apiSsoPath}/profile/settings/client`
		const res = await this.$axios.$get(url)
		dispatch('setUserDataAction', res)
	},

	async fetchPsychologist({dispatch}) {
		const url = `${apiSsoPath}/profile/settings/psychologist`
		const res = await this.$axios.$get(url)
		dispatch('setUserDataAction', res)
	},

	/**
	 *setUserDataAction
	 *
	 * @param {*} { commit }
	 * @param {*} userData
	 */
	setUserDataAction({commit}, userData) {
		const camelUserData = {}
		Object.keys(userData).forEach((option) => {
			camelUserData[snakeToCamel(option)] = userData[option]
		})
		camelUserData.isNotB2BUser = !camelUserData.isB2bClient
		camelUserData.isAgreeWithMailing = camelUserData.mailingAgreement
		commit('setUserData', camelUserData)
	},

	/**
	 *clearUserDataAction
	 *
	 * @param {*} { commit }
	 */
	clearUserDataAction({commit}) {
		commit('setInitialData')
	},

	/**
	 *updateProfileData
	 *
	 * @param {*} { commit }
	 * @returns
	 * @param email
	 * @param username
	 * @param time_zone
	 */
	async updateProfileData(
		{commit},
		{
			email,
			username,
			time_zone,
			mailing_agreement
		}
	) {
		const url = `${apiSsoPath}/profile/settings/client`
		const data = await this.$axios.$patch(url, {
			email,
			username,
			time_zone,
			mailing_agreement
		})

		return data
	},

	async rubricator(_) {
		const url = `${apiRubricatorPath}/client/rubricator/`
		return await this.$axios.$get(url)
	},

	async updateUser({commit}, data) {
		const url = `${apiSsoPath}/profile/settings/psychologist`
		return await this.$axios.$patch(url, data)
	},

	async getClientSubscriptions({commit}, payload = {}) {
		let url = `${apiAcquiringPath}/client/market/subscriptions`

		let paramsObj = {};

		if (payload.status) {
			paramsObj.subscription_status = payload.status;
		}

		if (payload.cost_category_id) {
			paramsObj.cost_category_id = payload.cost_category_id;
		}

		const params = new URLSearchParams(paramsObj).toString();

		if (params) {
			url = url + `?${params}`
		}

		const res = await this.$axios.$get(url)

		commit('setSubscriptions', res.items);
	},

	async getClientCertificates({commit}, status = '') {
		let url = `${apiAcquiringPath}/client/market/certificates`
		if (status) {
			url = url + `?subscription_status=${status}`
		}
		const res = await this.$axios.$get(url);
		console.log(res);

		commit('setCertificates', res.items);
	},

	async getClientPromocodes({commit}, cost_category_id = '') {
		let url = `${apiSsoPath}/promo_codes${cost_category_id ? '?cost_category_id=' + cost_category_id : ''}`
		const res = await this.$axios.$get(url)
		commit('setPromocodes', res);
	},

	async getSpecialistCost({commit}) {
		let url = `${apiSsoPath}/b2b/specialist-costs`;
		const res = await this.$axios.$get(url)
		commit('setSpecialistsCosts', res.items);
		return res.items;

	},

	async checkSessionQuotas({commit}, params) {
		let url = `${apiSsoPath}/b2b/groups/get-with-checking-session-quotas?specialization_type=${params.specialization_type}&slot_datetime=${params.slot_datetime}`;
		const res = await this.$axios.$get(url)
		//commit('setSessionQuotas', res);
		return res;
	},

	async getQuotas({commit}) {
		let url = `${apiSsoPath}/b2b/groups/get-quotas`;
		const res = await this.$axios.$get(url)
		commit('setQuotas', res);
		return res;
	},
}
