import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e75dbe8 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _16e4445d = () => interopDefault(import('../pages/login-instant.vue' /* webpackChunkName: "pages/login-instant" */))
const _8a643a6e = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _54612448 = () => interopDefault(import('../pages/client/application.vue' /* webpackChunkName: "pages/client/application" */))
const _430284a4 = () => interopDefault(import('../pages/client/check-devices.vue' /* webpackChunkName: "pages/client/check-devices" */))
const _3c32a5e5 = () => interopDefault(import('../pages/client/discount.vue' /* webpackChunkName: "pages/client/discount" */))
const _b51795b0 = () => interopDefault(import('../pages/client/email.vue' /* webpackChunkName: "pages/client/email" */))
const _e0f75fee = () => interopDefault(import('../pages/client/loyalty-program.vue' /* webpackChunkName: "pages/client/loyalty-program" */))
const _00b1605d = () => interopDefault(import('../pages/client/main.vue' /* webpackChunkName: "pages/client/main" */))
const _e7892cdc = () => interopDefault(import('../pages/client/payment.vue' /* webpackChunkName: "pages/client/payment" */))
const _01144504 = () => interopDefault(import('../pages/client/sessions/index.vue' /* webpackChunkName: "pages/client/sessions/index" */))
const _2be8b4cb = () => interopDefault(import('../pages/login/recovery.vue' /* webpackChunkName: "pages/login/recovery" */))
const _724e0432 = () => interopDefault(import('../pages/login/registration/index.vue' /* webpackChunkName: "pages/login/registration/index" */))
const _4695c992 = () => interopDefault(import('../pages/manuals/first-session.vue' /* webpackChunkName: "pages/manuals/first-session" */))
const _4ee4a282 = () => interopDefault(import('../pages/payment/education.vue' /* webpackChunkName: "pages/payment/education" */))
const _6fadb66d = () => interopDefault(import('../pages/payment/session.vue' /* webpackChunkName: "pages/payment/session" */))
const _1af6ebda = () => interopDefault(import('../pages/payment/training.vue' /* webpackChunkName: "pages/payment/training" */))
const _452abdb3 = () => interopDefault(import('../pages/psychologist/calendar.vue' /* webpackChunkName: "pages/psychologist/calendar" */))
const _77c5a022 = () => interopDefault(import('../pages/psychologist/dashboard.vue' /* webpackChunkName: "pages/psychologist/dashboard" */))
const _8fac46d6 = () => interopDefault(import('../pages/psychologist/sessions/index.vue' /* webpackChunkName: "pages/psychologist/sessions/index" */))
const _efd721e6 = () => interopDefault(import('../pages/search/others/index.vue' /* webpackChunkName: "pages/search/others/index" */))
const _7ec8e184 = () => interopDefault(import('../pages/search/psychologist/index.vue' /* webpackChunkName: "pages/search/psychologist/index" */))
const _f56165d6 = () => interopDefault(import('../pages/shop/certificates.vue' /* webpackChunkName: "pages/shop/certificates" */))
const _5c1ed386 = () => interopDefault(import('../pages/shop/payment.vue' /* webpackChunkName: "pages/shop/payment" */))
const _9824a3ee = () => interopDefault(import('../pages/shop/purchases.vue' /* webpackChunkName: "pages/shop/purchases" */))
const _e771f826 = () => interopDefault(import('../pages/shop/subscriptions.vue' /* webpackChunkName: "pages/shop/subscriptions" */))
const _2e3c7b26 = () => interopDefault(import('../pages/client/profile/settings.vue' /* webpackChunkName: "pages/client/profile/settings" */))
const _0abe4370 = () => interopDefault(import('../pages/client/sessions/session.vue' /* webpackChunkName: "pages/client/sessions/session" */))
const _9b68ced8 = () => interopDefault(import('../pages/client/sessions/start.vue' /* webpackChunkName: "pages/client/sessions/start" */))
const _1affd9a5 = () => interopDefault(import('../pages/psychologist/profile/edit.vue' /* webpackChunkName: "pages/psychologist/profile/edit" */))
const _89fbfc58 = () => interopDefault(import('../pages/psychologist/profile/main.vue' /* webpackChunkName: "pages/psychologist/profile/main" */))
const _30efb9be = () => interopDefault(import('../pages/psychologist/profile/settings.vue' /* webpackChunkName: "pages/psychologist/profile/settings" */))
const _6a40e176 = () => interopDefault(import('../pages/psychologist/sessions/details.vue' /* webpackChunkName: "pages/psychologist/sessions/details" */))
const _4a6f63bd = () => interopDefault(import('../pages/search/others/details.vue' /* webpackChunkName: "pages/search/others/details" */))
const _65d11474 = () => interopDefault(import('../pages/search/psychologist/details.vue' /* webpackChunkName: "pages/search/psychologist/details" */))
const _558cfcde = () => interopDefault(import('../pages/client/profile/manuals/apple.vue' /* webpackChunkName: "pages/client/profile/manuals/apple" */))
const _1ffec5e5 = () => interopDefault(import('../pages/client/profile/manuals/google.vue' /* webpackChunkName: "pages/client/profile/manuals/google" */))
const _1ff09b9d = () => interopDefault(import('../pages/client/profile/manuals/yandex.vue' /* webpackChunkName: "pages/client/profile/manuals/yandex" */))
const _ccce6b08 = () => interopDefault(import('../pages/login/oauth/_id.vue' /* webpackChunkName: "pages/login/oauth/_id" */))
const _59936b1a = () => interopDefault(import('../pages/login/registration/_id.vue' /* webpackChunkName: "pages/login/registration/_id" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4002703f = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/platform/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _5e75dbe8,
    name: "login"
  }, {
    path: "/login-instant",
    component: _16e4445d,
    name: "login-instant"
  }, {
    path: "/shop",
    component: _8a643a6e,
    name: "shop"
  }, {
    path: "/client/application",
    component: _54612448,
    name: "client-application"
  }, {
    path: "/client/check-devices",
    component: _430284a4,
    name: "client-check-devices"
  }, {
    path: "/client/discount",
    component: _3c32a5e5,
    name: "client-discount"
  }, {
    path: "/client/email",
    component: _b51795b0,
    name: "client-email"
  }, {
    path: "/client/loyalty-program",
    component: _e0f75fee,
    name: "client-loyalty-program"
  }, {
    path: "/client/main",
    component: _00b1605d,
    name: "client-main"
  }, {
    path: "/client/payment",
    component: _e7892cdc,
    name: "client-payment"
  }, {
    path: "/client/sessions",
    component: _01144504,
    name: "client-sessions"
  }, {
    path: "/login/recovery",
    component: _2be8b4cb,
    name: "login-recovery"
  }, {
    path: "/login/registration",
    component: _724e0432,
    name: "login-registration"
  }, {
    path: "/manuals/first-session",
    component: _4695c992,
    name: "manuals-first-session"
  }, {
    path: "/payment/education",
    component: _4ee4a282,
    name: "payment-education"
  }, {
    path: "/payment/session",
    component: _6fadb66d,
    name: "payment-session"
  }, {
    path: "/payment/training",
    component: _1af6ebda,
    name: "payment-training"
  }, {
    path: "/psychologist/calendar",
    component: _452abdb3,
    name: "psychologist-calendar"
  }, {
    path: "/psychologist/dashboard",
    component: _77c5a022,
    name: "psychologist-dashboard"
  }, {
    path: "/psychologist/sessions",
    component: _8fac46d6,
    name: "psychologist-sessions"
  }, {
    path: "/search/others",
    component: _efd721e6,
    name: "search-others"
  }, {
    path: "/search/psychologist",
    component: _7ec8e184,
    name: "search-psychologist"
  }, {
    path: "/shop/certificates",
    component: _f56165d6,
    name: "shop-certificates"
  }, {
    path: "/shop/payment",
    component: _5c1ed386,
    name: "shop-payment"
  }, {
    path: "/shop/purchases",
    component: _9824a3ee,
    name: "shop-purchases"
  }, {
    path: "/shop/subscriptions",
    component: _e771f826,
    name: "shop-subscriptions"
  }, {
    path: "/client/profile/settings",
    component: _2e3c7b26,
    name: "client-profile-settings"
  }, {
    path: "/client/sessions/session",
    component: _0abe4370,
    name: "client-sessions-session"
  }, {
    path: "/client/sessions/start",
    component: _9b68ced8,
    name: "client-sessions-start"
  }, {
    path: "/psychologist/profile/edit",
    component: _1affd9a5,
    name: "psychologist-profile-edit"
  }, {
    path: "/psychologist/profile/main",
    component: _89fbfc58,
    name: "psychologist-profile-main"
  }, {
    path: "/psychologist/profile/settings",
    component: _30efb9be,
    name: "psychologist-profile-settings"
  }, {
    path: "/psychologist/sessions/details",
    component: _6a40e176,
    name: "psychologist-sessions-details"
  }, {
    path: "/search/others/details",
    component: _4a6f63bd,
    name: "search-others-details"
  }, {
    path: "/search/psychologist/details",
    component: _65d11474,
    name: "search-psychologist-details"
  }, {
    path: "/client/profile/manuals/apple",
    component: _558cfcde,
    name: "client-profile-manuals-apple"
  }, {
    path: "/client/profile/manuals/google",
    component: _1ffec5e5,
    name: "client-profile-manuals-google"
  }, {
    path: "/client/profile/manuals/yandex",
    component: _1ff09b9d,
    name: "client-profile-manuals-yandex"
  }, {
    path: "/login/oauth/:id?",
    component: _ccce6b08,
    name: "login-oauth-id"
  }, {
    path: "/login/registration/:id",
    component: _59936b1a,
    name: "login-registration-id"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/*",
    component: _4002703f,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
