
	import {mapActions, mapGetters, mapState} from 'vuex'
	import PopupFeedbackForm from '@/components/Client/Popup/PopupFeedbackForm'
	import PopupOverlay from '@/components/Client/Popup/PopupOverlay.vue'
	import PopupLogout from '@/components/Client/Popup/PopupLogout.vue'
	import UiMenuUser from '@/components/UI/MenuLeftNew/UiMenuUser.vue'
	import UiMenuItem from '@/components/UI/MenuLeftNew/UiMenuItem.vue'
	import UiMenuBottom from '@/components/UI/MenuLeftNew/UiMenuBottom.vue'
	import gtmButtonClick from '@/mixins/gtm-button-click'
	import utmMixin from '@/mixins/utm'
	import {NAV_FOR_CLIENT, NAV_FOR_B2B_CLIENT, NAV_FOR_PSY, NAV_FOR_ANONYMOUS, NAV_FOR_B2B_WITH_OTHER_SPECIALISTS, NAV_FOR_OTHER_SPECIALISTS} from "@/constants/navigation";
	import {userTypes} from "@/store/user";

	export default {
		components: {
			UiMenuUser,
			UiMenuItem,
			UiMenuBottom,
			PopupFeedbackForm,
			PopupOverlay,
			PopupLogout,
		},
		mixins: [gtmButtonClick, utmMixin],
		props: {
			isOpen: {type: Boolean, default: false},
		},
		data() {
			return {
				isFeedbackFormVisible: false,
				isVisibleLogout: false,
				messageOk: '',
				messageErr: '',
			}
		},
		computed: {
			...mapState({
				user: (s) => s.user,
			}),
			...mapState('user', ['userType', 'specialistsCosts']),
			...mapGetters('user', ['gtmUserPage']),
			isAuth() {
				return this.$store.state.auth.isAuth
			},
			mode() {
				return this.user.userType.toLowerCase()
			},
			position() {
				switch (this.mode) {
					case 'client': {
						return this.user.b2bProfile?.b2b_company || 'Пользователь';
					}
					case 'psychologist': {
						return 'Специалист';
					}
					case 'admin': {
						return 'Администратор';
					}
					default: {
						return ''
					}
				}
			},
			availableSpecialists() {
				return this.specialistsCosts ? this.specialistsCosts.filter(item => item.is_available === true && item.specialization_type !== 'PSYCHOLOGY') : [];
			},
			menu() {
				if (this.user && this.user.userType) {
					if (this.user.userType === userTypes.client) {
						if (this.user.b2bProfile?.b2b_company) {
							if (this.availableSpecialists?.length) {
								return NAV_FOR_B2B_WITH_OTHER_SPECIALISTS;
							} else {
								return NAV_FOR_B2B_CLIENT;
							}
						}
						return NAV_FOR_CLIENT;
					} else if(this.user?.specializationType === 'PSYCHOLOGY'){
						return NAV_FOR_PSY;
					} else {
						return NAV_FOR_OTHER_SPECIALISTS;
					}
				}
				return NAV_FOR_ANONYMOUS;
			},
		},
		methods: {
			...mapActions({
				storeSendFeedback: 'feedback/sendFeedback',
				getSpecialistCost: 'user/getSpecialistCost'
			}),
			...mapActions({
				storeLogout: 'auth/logoutUser',
			}),
			logout() {
				this.storeLogout().then(() => {
					this.$router.push('/search/psychologist')
				})
			},
			openLogoutPopup() {
				this.isVisibleLogout = true
				this.gtmButtonClick(
					'exit',
					'left-menu',
					'button',
					'click',
					this.gtmUserPage
				)
			},
			closeLogoutPopup(e) {
				this.isVisibleLogout = false

				if (e) {
					this.logout()
				}
			},
			onCloseFeedbackPopup() {
				this.isFeedbackFormVisible = false
			},

			onOpenFeedbackPopup() {
				this.isFeedbackFormVisible = true
			},

			async onSubmitFeedbackForm(data) {
				if (!process.client) return

				const result = await this.storeSendFeedback(data)
				if (result) {
					this.messageOk = 'Ваше сообщение было успешно отправлено!'
					setTimeout(() => {
						this.onCloseFeedbackPopup()
						this.$refs.feedbackForm.clearFields()
						this.messageOk = ''
					}, 2000)
				} else {
					this.messageErr = 'Что-то пошло не так:( Повторите отправку'
				}
			},
			// onMenuItemClick(item) {
			// 	this.gtmButtonClick(
			// 		item.title,
			// 		'left-menu',
			// 		'button',
			// 		'click',
			// 		this.gtmUserPage
			// 	);
			//
			// 	// this.$emit('menuItemClick');
			// },
		},
		async mounted() {
			if (this.user?.userType === userTypes.client && this.user?.isB2bClient) {
				await this.getSpecialistCost();
			}
		}
	}
