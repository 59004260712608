import {serializeSearchParams} from "~/helpers/network";

const apiModulePath = `/rubricator/api/v1`

const initialState = {
	isFromTherapistSearch: false,
	isAdditionalTherapistSearch: false,
	psychologistInfo: {},
	isLoading: false,
	lastSessionsCount: 0,
	expectedSessionsCount: 0,
	sessions: [],
}

export const state = () => ({
	...initialState
})

export const mutations = {
	setPsychologistInfo(state, data) {
		state.psychologistInfo = data
	},
	setLoading(state, payload) {
		state.isLoading = payload
	},
	setFromTherapistSearch(state, isFromTherapistSearch) {
		state.isFromTherapistSearch = isFromTherapistSearch
	},
	setIsAdditionalTherapistSearch(state, isAdditionalTherapistSearch) {
		state.isAdditionalTherapistSearch = isAdditionalTherapistSearch
	},
	setTotalLastSessions(state, lastSessionsCount) {
		state.lastSessionsCount = lastSessionsCount
	},

	setTotalExpectedSessions(state, expectedSessionsCount) {
		state.expectedSessionsCount = expectedSessionsCount
	},
	setSessions(state, sessions) {
		state.sessions = sessions;
	},
}

export const getters = {
	getPsychologistInfo(state) {
		return state.psychologistInfo
	}
}

export const actions = {
	async infoPsy({commit}, psychologistId) {
		commit('setLoading', true)
		const url = `${apiModulePath}/psychologist/psychologist_application/`;

		try {
			const response = await this.$axios.$get(url);

			const data = {
				...response,
				gender: {
					value: response.gender,
					name: response.gender,
					selected: true,
				},
				age_category: response.age_category.map((item) => (item.id)),
				therapy_formats: response.therapy_formats.map((item) => ({
					...item,
					value: item.id,
					selected: true
				})),
				therapy_methods: response.therapy_methods.map((item) => ({
					...item,
					value: item.id,
					selected: true
				})),
				no_work_issues: response.no_work_issues.map((item) => ({
					...item,
					value: item.id,
					selected: true
				})),
				preferred_issues: response.preferred_issues.map((item) => ({
					...item,
					value: item.id,
					selected: true
				})),
			}
			commit('setPsychologistInfo', JSON.parse(JSON.stringify(data)))
			commit('setLoading', false)
			return data;
		} catch (err) {
			if (this.$sentry) {
				this.$sentry.captureException(err)
			}

			return err
		}
	},

	async updatePsy(_, data) {
		const url = `${apiModulePath}/psychologist/psychologist_application/`;
		const form = {
			...data,
			preferred_issues: (data.preferred_issues || []).filter(item => !!item).length > 0 ? (data.preferred_issues || []).filter(item => !!item) : undefined,
			no_work_issues: (data.no_work_issues || []).filter(item => !!item).length > 0 ? (data.no_work_issues || []).filter(item => !!item) : undefined,
			therapy_methods: (data.therapy_methods || []).filter(item => !!item).length > 0 ? (data.therapy_methods || []).filter(item => !!item) : undefined
		}
		await this.$axios.patch(url, form)
	},

	async getPsyApp({commit, state}, params) {
		const {id, search_id, is_public} = params;
		const url = `${apiModulePath}/${is_public ? 'public' : 'client'}/psychologist_application/psychologist/${id}`;
		const response = await this.$axios.get(url, {
			params: {
				from_therapist_search: state.isFromTherapistSearch,
				is_additional_therapist_search: state.isAdditionalTherapistSearch,
				search_id
			}
		})
		commit('setPsychologistInfo', response.data)
		return response;
	},

	async getSessions({commit}, query = {}) {
		try {
			const params = serializeSearchParams({
				page_size: 100,
				...query,
				page: query?.page && query?.page > 0 ? query?.page - 1 : 0,
			})

			const url = '/sessions/api/v1/psychologist/session'
			const data = await this.$axios.$get(url, {params})

			commit('setSessions', data.results || [])
			if (query?.session_status?.includes('FINISHED')) {
				commit('setTotalLastSessions', data.count)
			}
			if (query?.session_status?.includes('EXPECTED')) {
				commit('setTotalExpectedSessions', data.count)
			}
			return data
		} catch (err) {
			if (this.$sentry) {
				this.$sentry.captureException(err)
			}

			throw new Error(err)
		}
	},
}
