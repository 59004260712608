import {v4 as uuidv4} from 'uuid';
import {userTypes} from "@/store/user";

export const state = () => ({
	stories: [],
	stories_idempotency_key: uuidv4()
})

export const mutations = {
	setStories(state, stories) {
		state.stories = stories
	},
}

export const actions = {
	async getStories({rootState, commit}) {
		let b2bCompanyId = rootState.user.b2bCompany?.id;

		const res = await this.$axios.$get(`/filestorage/api/v1/stories/${rootState.user.userType === userTypes.client ? 'client': 'psychologist'}/stories${b2bCompanyId ? '?b2b_company_id=' + b2bCompanyId : ''}`)
		commit('setStories', res?.items || [])
	},
	async getStory({commit}, id) {
		const url = `/filestorage/api/v1/stories/client/stories/${id}`;
		return await this.$axios.$get(url)
	},
	async logView({state, commit}, card) {
		const url = `/filestorage/api/v1/stories/client/stories/${card.story_id}/cards/${card.id}/log-view?stories_idempotency_key=${state.stories_idempotency_key}`;
		return await this.$axios.$post(url)
	},
	async logActionClick({state,commit}, card) {
		const url = `/filestorage/api/v1/stories/client/stories/${card.story_id}/cards/${card.id}/log-action-click?stories_idempotency_key=${state.stories_idempotency_key}`;
		return await this.$axios.$post(url)
	},
}
